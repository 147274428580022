a {
  text-decoration: none;
}

.column-at {
    float: left;
    width: 50%;
    justify-content: center;
    text-align: center;
    color: rgb(2, 43, 82);
    display: block;
    margin-left: auto;
  margin-right: auto;
  }
  
  .row-at:after {
    content: "";
    display: table;
    clear: both;
  } 


  .billede1{
    width: 170px;
    opacity: 95%;
  }

  .overskrifter1{
    text-align: center;
    justify-content: center;
    display: flex;
    margin-top: -0.1rem;
    color: rgb(2, 43, 82);
    z-index: 7;
  }

  .lorem-tekst{
    font-size: 12px;
    text-align: left;
    justify-content: center;
    display: flex;
    padding-left: 11px;
    margin-top: -1rem;
  }