.banner {
    width: 100%;
}

body{
    font-family: montserrat,sans-serif;
  }
.city {
    margin: 1em;
}


.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

}


/* Container holding the image and the text */
.citycontainer {
    position: relative;
  text-align: center;
  color: white;
  }
  
  /* Bottom right text */
  .text-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
    border: 1px solid grey;
  }

  .text-block h4 {
    margin: 0;

  }

  .home {
    text-align: center;
    color:rgb(2, 43, 82);
    font-size: 1.2em;
  }

  .homep {
    text-align: center;
    color: rgb(2, 43, 82);
    font-size: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }


  .minde-btn {
    background-size: cover;
    border: solid 2px #1c3e77;
    border-radius: 25px;
    color: white;
    padding: 40px 60px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
  }
  
  .minde-btn1 {
    background-image: url(/public/images/homebanner.png);
  }