.category {
    display: grid;
    width: 90%;
    place-items: center;
    place-content: center;
    padding: 1em;
    text-decoration: none;
 
}
.category > * {
    grid-area: 1 / 1;

  }

.category > img {
    width: 100%;
    opacity: 80%;

}

.category > div {
    color: white;
    font-size: 32px;
    font-family: montserrat,sans-serif;
    font-weight: 700;
    font-style: normal;
    z-index: 2;
}



.placename {
  text-decoration: none;
}