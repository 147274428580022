body{
  font-family: montserrat,sans-serif;
}

.navbar{
    background-color: #ffffff;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 100%;
    padding: 0.5rem 0.01rem;
    z-index: 2;
    position: fixed;
    display: flex;
    overflow: hidden;
    bottom: 0;
    gap: 0.5rem;
    border-top: solid;
    border-width: 1px;
    margin-bottom: 0px;
}

.navbar a {
    text-decoration: none;
    background: transparent;
    padding: 0.5rem;
    border-radius: 0%;
    color: rgb(2, 43, 82);
    text-align: center;
    display: block;
}

.navbar a:hover {
    color: rgb(231, 0, 19);
  }

.navbar a.active {
    color: rgb(231, 0, 19);
  }

  .nav-icons{
    font-size: 33px;
  }