body{
    font-family: montserrat,sans-serif;
  }

.header-settings{
    text-align: center;
    font-size: 20px;
    color: rgb(2, 43, 82);
}

.linje{
    width: 80%;
    color: rgb(2, 43, 82);
}

/* Pil ikon */
.højre-pil1{
    position: relative;
    top: 2px;
}

.højre-pil2{
    position: relative;
    left: 53px;
    cursor: pointer;
    font-size: 18px;
    top: 2px;
}

.højre-pil3{
    position: relative;
    left: 137px;
    cursor: pointer;
    font-size: 18px;
    top: 2px;
}

/* oversæt knap */
.translate-knap{
background-color: #ffffff;
cursor: pointer;
margin-left: 8rem;
}

.translate-knap:hover{
    background-color: rgb(162, 169, 173);
}

/* tekstindhold */
.settings-tekst{
font-size: 15px;
padding-left: 3rem;
color: rgb(2, 43, 82);
font-family: montserrat,sans-serif;
}
