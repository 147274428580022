body{
    font-family: montserrat,sans-serif;
  }


  .hotel{
    text-align: center;
  }

  .hotel-ikon2{
    font-size: 50px;
    padding-top: 15px;
  } 

  .header-hotel{
    text-align: center;
    margin-top: -5px;
    color: rgb(2, 43, 82);
  }


   /* Main indhold */
.column-hotel {
  float: left;
  width: 50%;
  justify-content: center;
  text-align: center;
  color: rgb(2, 43, 82);
}

.row-hotel:after {
  content: "";
  display: table;
  clear: both;
}