body{
  font-family: montserrat,sans-serif;
}

.header-discover{
    text-align: center;
    color: rgb(2, 43, 82);
}

.under-rubrik-discover{
    text-align: left;
    font-weight: normal;
    padding: 2px;
    padding-left: 35px;
    padding-right: 25px;
    font-size: 15px;
    color: rgb(2, 43, 82);
}

/* søge felt*/
.søgefelt2{
  text-align: center; 
  padding-right: 1rem;
  color: rgb(2, 43, 82);
}

.søg-ikon2{
  position: relative;
  top: 3px;
  left: 2.8vh;
  color: #000000;
}

.input-søge2{
  padding: 5px 1.7rem;
  border-style: solid;
  border-width: 1.5px;
  color: rgb(2, 43, 82);
}


/* muligheds content/ikoner */
.mulighed{
  background-color: #ffffff;
  
}

.mulighedsikoner{
  gap: 2rem;
  padding: 2px;
  text-align: center;
  display: flex;
  text-decoration: none;
}

.mulighedstekst{
display: flex;
gap: 1.9rem;
padding-right: 13px;
padding-left: 9px;
text-align: center;
justify-content: center;
margin-top: -15px;
}

.discover-ikon-tekst{
  color: rgb(2, 43, 82);
  font-size: 12px;
  cursor: pointer;
}

.attraktioner1{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

.hotel1{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

.events1{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

.aktiviteter1{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

.tilbage-pil{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

.frem-pil{
  font-size: 40px;
  color: #000000;
  cursor: pointer;
}

/* lille tekst under muligheds content*/
.lille-tekst-container2{
  text-align: center;
}

.lille-tekst2{
  font-size: 10px;
  color: rgb(2, 43, 82);
} 


/* Main indhold */
.column-discover {
    float: left;
    width: 50%;
    justify-content: center;
    text-align: center;
    color: rgb(2, 43, 82);
  }
  
  .row-discover:after {
    content: "";
    display: table;
    clear: both;
  }