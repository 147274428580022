
body{
    font-family: montserrat,sans-serif;
  }

/*intro*/
.header-plan{
    text-align: center;
    color: rgb(2, 43, 82);
}

.under-rubrik-plan{
    text-align: center;
    font-weight: normal;
    padding: 2px;
    padding-left: 25px;
    padding-right: 25px;
    color: rgb(2, 43, 82);
}

/* dato/print knap og ikoner */
.dage-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 10px;
    margin-right: -1.5rem;
    border-radius: 12px;
    font-size: 13.4px;
    margin-left: 1.7rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.7rem;
    color: rgb(2, 43, 82);
    border-color: rgb(2, 43, 82);
    border-width: 1.5px;
}

.pil-ned{
    position: relative;
    cursor: pointer;
    top: 4px;
    font-size: 18px;
    color: black;
}

.print-ikon{
    font-size: 20px;
    position: relative;
    cursor: pointer;
    top: 4px;
    color: rgb(2, 43, 82);
}

.kalender-ikon{
    font-size: 20px;
    position: relative;
    cursor: pointer;
    top: 4px;
    color: rgb(2, 43, 82);
}


/* main indhold - tidsplan bokse og knapper*/
.dag-overskrift{
    padding-left: 1.5rem;
    color: rgb(2, 43, 82);
}

.afgang-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 10px;
    margin-right: -1.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-width: 1.5px;
    color: rgb(2, 43, 82);
}

.transport-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 10px;
    margin-right: -1.5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-width: 1.5px;
    color: rgb(2, 43, 82);
}

.ankomst-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 11px;
    margin-right: -1.5rem;
    margin-left: 1.5rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-width: 1.5px;
    color: rgb(2, 43, 82);
}

.aktivitet-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 15px;
    margin-right: -1.5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-width: 1.5px;
    color: rgb(2, 43, 82);
}

.begivenhed-knap{ 
    background-color: #FFFFFF;
    display: inline;
    padding: 5px 10px;
    margin-right: -1.5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-width: 1.5px;
    color: rgb(2, 43, 82);
}

.slet-ikon{
    position: relative;
    cursor: pointer;
    top: 7px;
    font-size: 27px;
    padding-left: 2.6rem;
    color: black;
}

.pil-ned2{
    position: relative;
    cursor: pointer;
    padding-left: 1.5rem;
    top: 4px;
    font-size: 18px;
    color: black;
}

/* indholdskasse */

.indholdskasse{
    padding-top: 0.5rem;
}

.timing{
    margin-left: 1.8rem;
    width: max-content;
    padding: 5px 0 0 0;
    margin-bottom: -3.5rem;
    font-weight: bold;
    color: rgb(2, 43, 82);
}

.indholdstekst{
    margin-left: 1.5rem;
    padding-right: 8px;
    border-style: solid;
    border-width: 1px;
    width: max-content;
    padding-top: 0.5rem;
    font-size: 14px;
    color: rgb(2, 43, 82);
    border-color: black;
}

.indholdstekst2{
    margin-left: 4.9rem;
    margin-top: -0.5rem;
    color: rgb(2, 43, 82);
    border-color: black;
}

/* tekst for aktivitet delen */
.indholdstekst3{
    margin-left: 4.9rem;
    margin-top: -0.5rem;
    padding-bottom: 0.3rem;
    color: rgb(2, 43, 82);
    border-color: black;
}

/* tekst for billet delen */

.indholdstekst4{
    margin-left: 4.9rem;
    margin-top: -0.5rem;
    padding-bottom: 0.3rem;
    font-size: small;
    border-color: black;
}

.bus-ikon{
    margin-left: 3.3rem;
    font-size: 20px;
    margin-bottom: -1rem;
    color: black;
}

.edit-ikon{
    padding-left: 2.5rem;
    font-size: 22px;
    color: black;
}

/* edit ikon for aktivitet delen */
.edit-ikon2{
    padding-left: 1rem;
    margin-top: -0.2rem;
    font-size: 22px;
    color: black;
}

/* edit ikon for billet delen */
.edit-ikon3{
    padding-left: 0.5rem;
    margin-top: -0.2rem;
    font-size: 22px;
    color: black;
}

.kajak-ikon{
    margin-left: 3.3rem;
    font-size: 20px;
    margin-bottom: -1rem;
    color: black;
}

.ticket-ikon{
    margin-left: 3.3rem;
    font-size: 20px;
    margin-bottom: -1rem;
    color: black;
}

/* Tilføj ny sektion/ */

.tilføj{
    text-align: center;
} 

.tilføj-tekst{
    text-align: center;
    margin-top: -0.5rem;
    font-weight: bold;
    margin-bottom: -0.5rem;
    color: rgb(2, 43, 82);
}

.tilføj-ikon{
    text-align: center;
    justify-content: center;
    font-size: 35px;
}