body{
    font-family: montserrat,sans-serif;
  }


  .activities{
    text-align: center;
  }

  .activities-ikon2{
    font-size: 50px;
    padding-top: 15px;
  } 

  .header-activities{
    text-align: center;
    margin-top: -5px;
    color: rgb(2, 43, 82);
  }

   /* Main indhold */
.column-activities {
  float: left;
  width: 50%;
  justify-content: center;
  text-align: center;
  color: rgb(2, 43, 82);
}

.row-activities:after {
  content: "";
  display: table;
  clear: both;
}